import React from "react";
import "./chisiamo.css";

const Chisiamo = () => {
    return (
        <div className="unimy__chisiamo">
            <div className="unimy__chisiamo-contents">
                <h1 className="color_title">CHI SIAMO?</h1>
                <p>
                    UniMy nasce come risposta al crescente interesse condiviso tra giovani e mercato, rappresentando il primo punto di incontro tra studenti intraprendenti e professionisti. L’obiettivo consiste nel creare uno spazio che dia la possibilità ai giovani di trasmettere caratteristiche personali come capacità, passione, creatività e intraprendenza, non emergenti da un tradizionale curriculum, e nel fornire uno strumento per business, professionisti o altri giovani di individuare nuovi talenti.
                    UniMy mira, dunque, ad essere il primo social network accademico-professionale, ovvero un posto dedicato ai giovani e che permetta loro di mettersi in evidenza, stringere legami e   rimanere aggiornati sul mercato in termini accademici, professionali e globali.
                </p>
            </div>
        </div>
    );
}

export default Chisiamo