import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
//import "./footer.css";

const Footer = () => {
    return (""
    // <div>
    //     <div className="unimy__footer">
    //         <div className="unimy__footer-content">
    //             <h1>
    //                 Vuoi aiutarci?
    //             </h1>
    //             <p>
    //                 Aiutaci con questa indagine!
    //             </p>
    //             <a href= "https://forms.gle/G6zZ5RdhpUtBD5WP6" target="_blank" className="unimy__footer-content-link_triennale">Studenti Triennale</a>
    //             <a  href= "https://forms.gle/eyddQ3tYwcaZDJSw9" target="_blank" className="unimy__footer-content-link_magistrale">Studenti Magistrale / Neolaureati</a>
    //             <div className="unimy__footer-content_social">
    //                 <p>
    //                     Se ti abbiamo incuriosito, ci trovi qua per il momento #WIP
    //                 </p>
    //                 <p>
    //                     mail: reachus.unimy@gmail.com
    //                 </p>
    //                 <p>
    //                     insta: @unimy.it
    //                 </p>
    //             </div>
    //         </div>
    //     </div>
    // </div>
)}

export default Footer