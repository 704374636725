import React, { useState, useRef, useEffect} from 'react';

function Textc({ isFocused }) {
    const textRef = useRef(null);
    const [cancelAnimation, setCancelAnimation] = useState(false);

    useEffect(() => {
        if (cancelAnimation) {
            textRef.current.style.display = "none";
        }
    }, [cancelAnimation]);

  return (
    <p className={isFocused ? 'visible' : 'hidden'}>
        La mission di UniMy è di fornire un luogo ai giovani dove possono mettersi su un piano che definiamo “accademico-professionale”. 
        Ma cosa significa esattamente? Ti viene in mente LinkedIn? Bene è un buon punto di partenza. 
        LinkedIn ha rivoluzionato il mondo del lavoro non solo perché consente di caricare un CV online, ma perché permette di creare un profilo completo che riflette la personalità e la competenza dell'individuo. Tuttavia, non basta creare un profilo per ottenere grandi risultati, infatti le piattaforme premiano la qualità dei contenuti e l'interazione tra gli utenti.
        Secondo una nostra ricerca, la maggior parte degli studenti non può che utilizzare LinkedIn solo come un portavoce del proprio CV, precludendosi la possibilità di suscitare sincero interesse verso la propria persona; forse allentando ma senza rompere il circolo vizioso che impone “esperienza per fare esperienza”.

        Noi, UniMy, vogliamo rompere questo circolo vizioso e costruire un vero e umano ponte tra il mondo accademico e quello del lavoro con una solida community che permetta ai giovani di condividere e interagire con altri giovani e professionisti, facendo così emergere un mondo fino ad oggi invisibile.
    </p>
  );
}

export default Textc;