import {Networking, Chisiamo} from "./components";
import {Footer, Header} from "./containers";
import "./App.css";

export default function App() {
  return (
  <div className="App">
    <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no, viewport-fit=cover"></meta>
    <div className="grad__bg">
      <Header/>
      <Networking/>
      <Chisiamo/>
      <Footer/>
    </div>
  </div>
  );
}

