import React from "react";
import "./header.css";

const Header = () => {
    return (
        <div className="unimy__header" id="home">
            <div className="unimy__header-content">
                <h1 className="color__title">
                UniMy
                </h1>
                <h3 className="color__subtitle">
                    il tuo mondo accademico professionale
                </h3>
            </div>
        </div>
    );
}

export default Header