import React, { useState, useRef, useEffect} from 'react';

function Textl({ isFocused }) {
    const textRef = useRef(null);
    const [cancelAnimation, setCancelAnimation] = useState(false);

    useEffect(() => {
        if (cancelAnimation) {
            textRef.current.style.display = "none";
        }
    }, [cancelAnimation]);

  return (
    <p className={isFocused ? 'visible' : 'hidden'}>
      Più di due terzi dei giovani si informa tramite social network ma non rientra nelle principali ragioni per cui vengono utilizzati. (si rileggerla può aiutare)
Instagram, per esempio, nato per rimanere in contatto con amici tramite la condivisione di foto mantiene tutt'ora questa sua funziona identificativa ma l'esigenza di trattenere l'utente sullo schermo ha attirato sulla piattaforma sempre più contenuti contribuendo inevitabilmente alla rivoluzione mediatica cui abbiamo assistito negli ultimi 10 anni. 
È dunque inevitabile che questi siano divenuti dispersivi e che la “Gen-Z” sia quella a risentirne maggiormente.
È anche vero però che la presenza di una community spinga le “testate mediatiche” ad evolversi continuamente e dunque rendere la comunicazione e complessivamente l'informazione, più stimolante.
UniMy vuole essere una piattaforma moderna ma allo stesso tempo un luogo che spinga l'utente focalizzarsi sulla qualità piuttosto che sulla quantità.
    </p>
  );
}

export default Textl;
