import React, { useState } from "react";
import "./networking.css";
import Textl from "./Text/Textl";
import Textc from "./Text/Textc";
import Textr from "./Text/Textr";

const Networking = () => {
    const [isFocused, setIsFocused] = useState(false);

    return (<div>
        <div className="unimy__networking">
            <div className="unimy__networking-contents">
                <div className="unimy__networking-contents_lbox" tabIndex="0">
                    <h2 className="ltitle">Qualità</h2>
                    <Textl className="ltext" isFocused={isFocused} />
                </div>
                <div className="unimy__networking-contents_cbox" tabIndex="0">
                    <h1 className="ctitle">
                        Opportunità
                    </h1>
                    <Textc className="ctext" isFocused={isFocused} />
                </div>
                <div className="unimy__networking-contents_rbox" tabIndex="0">
                    <h2>Network</h2>
                    <Textr className="rtext" isFocused={isFocused} />
                </div>
            </div>
        </div>
    </div>
    );
}

export default Networking