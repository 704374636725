import React, { useState, useRef, useEffect} from 'react';

function Textr({ isFocused }) {
    const textRef = useRef(null);
    const [cancelAnimation, setCancelAnimation] = useState(false);

    useEffect(() => {
        if (cancelAnimation) {
            textRef.current.style.display = "none";
        }
    }, [cancelAnimation]);

  return (
    <p className={isFocused ? 'visible' : 'hidden'}>
        Uno degli aspetti fondamentali e più avvincenti del mondo del lavoro
        sono i legami che questo permette di instaurare con persone stimolanti
        in circostanze coinvolgenti. Seppur l'innovazione delle industrie vede
        sempre di più i giovani come protagonisti, persiste un netto divario tra
        il mondo accademico e professionale in termini di nuovi strumenti e di scalabilità
        di quelli esistenti, volti a conoscere e rimanere in contatto con nuove
        persone al fine di imparare, scambiarsi idee, arricchirsi, insomma fare networking! 
    </p>
  );
}

export default Textr;